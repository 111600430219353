const { REACT_APP_API_ENDPOINT } = process.env;
const API_ENDPOINT = REACT_APP_API_ENDPOINT.replace(/\/$/, '');

const _url = (path) => `${API_ENDPOINT}${path}`;
const _headers = (src, targ) => Object.assign(src, targ);
const _randInt = (min, max) => Math.floor(Math.random() * (max - min) + min);

/**
 * set up AbortController
 * resolves: "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method."
 */
let controller = null;
let signal = null;

if (typeof window.AbortController !== 'undefined') {
    controller = new AbortController();
    signal = controller.signal;
}

class ApiError extends Error {
    constructor(message, response = {}) {
        super(message);

        this.name = 'ApiError';
        this.status = response.status || null;
        this.statusText = response.statusText || null;
        this.url = response.url || null;
    }

    toString() {
        return `[${this.status}:${this.statusText}] ${this.message} (${this.url})`;
    }
}

/**
 * Handles and formats response errors in a promise context
 * @param {Response} fetch response object @see https://developer.mozilla.org/en-US/docs/Web/API/Response
 * @returns {Promise} Promise object throwing an exception (being catched in flow)
 */
const responseError = function(response) {
    return response.text()
        .then((text) => {
            throw new ApiError(text.replace(/(<([^>]+)>)/ig, ''), response);
        });
};

/**
 * Handles GET|POST application/json data requests
 * @returns {Promise} Promise object or throws ApiError
 */
const request = async function(path, data=null, headers={}) {
    const url = _url(path);

    const options =  {
        signal,
        credentials: 'include', // @see https://developer.mozilla.org/en-US/docs/Web/API/RequestInit#credentials
        headers: _headers(headers || {}, {
            'Content-type': 'application/json; charset=UTF-8',
        }),
    };

    if (data) {
        options.method = 'POST';
        options.body =  JSON.stringify(data);
    }

    return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                return responseError(response);
            }
            return response.json();
        })
        .catch((error) => {
            if(error && error.name === 'AbortError') {
                return;
            }
            if(error.name === 'ApiError') {
                throw error;
            }
            throw new ApiError(error.toString(), { url });
        });
};

/**
 * Handles POST multipart/form data requests
 * @returns {Promise} Promise object or throws ApiError
 */
const formRequest = async function(path, data, media, headers={}) {
    const url = _url(path);

    const options =  {
        signal,
        credentials: 'include', // @see https://developer.mozilla.org/en-US/docs/Web/API/RequestInit#credentials
        method: 'POST',
        headers,
    };

    data = data || {};
    media= media || []; // array required
    const formData = new FormData();

    Object.keys(data).forEach(name => formData.append(name, data[name]));
    formData.append('media', media);

    return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                return responseError(response);
            }
            return response.text();
        })
        .catch((error) => {
            if(error && error.name === 'AbortError') {
                return;
            }
            if(error.name === 'ApiError') {
                throw error;
            }
            throw new ApiError(error.toString(), { url });
        });
};

/**
 * @returns {Promise}
 */
const albums = async function() {
    return request('/api/albums');

};

/**
 * @returns {Promise}
 */
const album = async function(id, data=null) {
    return request(`/api/album/${id}`, data);
};

/**
 * @returns {Promise}
 */
const guidedPlans = async function() {
    return request('/api/guidedplans');

};

/**
 * @returns {Promise}
 */
const guidedPlan = async function(id) {
    return request(`/api/guidedplan/${id}`);
};

/**
 * GET, POST (if data)
 * @returns {Promise}
 *
 * @see controllers.py:guidedplan_prompt_form() 3554
 */
const guidedPlanPrompt = async function(id, data=null, media=null) {
    // POST application multiplart/formdata
    if (data) {
        return formRequest(`/guidedplan_prompt_form/${id}`, data, media);
    }
    // POST application/json
    return request(`/guidedplan_prompt_form/${id}`);
};

/**
 * GET, POST (if data)
 * @returns {Promise}
 *
 * @see controllers.py:guidedplan_prompt_json() 3815
 */
const guidedPlanPromptJson = async function(id, data=null, media=null) {
    // POST application multiplart/formdata
    if (data) {
        return request(`/guidedplan_prompt_json/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }
    // POST application/json
    return request(`/guidedplan_prompt_json/${id}`);
};

/**
 * @returns {Promise}
 */
const profile = async function(data=null) {
    return request('/auth/api/profile', data)
    .then(res => res.user);
};

/**
 * @returns {Promise}
 */
const login = async function(data) {
    return request('/auth/api/login', data)
    .then(res => res.user);
};

/**
 * @returns {Promise}
 */
const logout = async function() {
    return request('/auth/api/logout')
    .then(res => res);
};

/**
 *  @see uppy xhr init
 */
const mediaUploadEndpoints = {
    album: (album_id) => _url(`/api/albums/${album_id}/upload`),
    albumPage: (album_id, page_id) => _url(`/api/albums/${album_id}/${page_id}/upload`),
    albumPageParagraph: (album_id, page_id, paragraph_id) => _url(`/api/albums/${album_id}/${page_id}/${paragraph_id}/upload`),
    guidedPlan: (plan_id) => _url(`/api/guidedplans/${plan_id}/upload`),
    guidedPlanPrompt: (plan_id, prompt_id) => _url(`/api/guidedplans/${plan_id}/${prompt_id}/upload`),
    guidedPlanPromptJson: (plan_id, prompt_id) => _url(`/api/guidedplans/${plan_id}/${prompt_id}/upload`),
};

/**
 * @returns {Promise}
 */
const mock = function(data) {
    const delay = _randInt(1000, 3000);
    let id;

    return new Promise((resolve) => {
        id = setTimeout(() => {
            resolve(data);
            clearTimeout(id);
        }, delay);
    })
};

const Api = {
    request,

    profile,

    login,
    logout,

    albums,
    album,

    mock,

    guidedPlan,
    guidedPlans,
    guidedPlanPrompt,
    guidedPlanPromptJson,

    url: _url,
    mediaUploadEndpoints,
};


export default Api;
