import { Link, useNavigate } from "react-router-dom";

import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import { routes } from '../navigation';

import { Styles, classNames } from '../styling';


const Todo  = function({ route=null }) {
    const navigate = useNavigate();
    const extra = (route && route.name) ? route.name : '';

    return (
        <Page.Simple>
            <PageTitle route={ routes.todo } />
            <div>
                <div className="font-bold mb-4">
                    This page needs to be created { (extra) ? ':' : '!' }
                    { extra && <pre className="inline mt-2 ml-2 text-status-invalid-dark">{ extra }</pre> }
                </div>

                <div className="mb-4">
                    <Link to="/" className={ Styles.btnPrimary }>Home</Link>
                    <button className={ classNames('ml-4', Styles.btnSecondary) } onClick={ () => navigate(-1) }>Back</button>
                </div>
            </div>
        </Page.Simple>
    );
};


export default Todo;
