import { classNames } from '../styling';


const Image = function({ src, alt, className, imageHeightClass }) {
    if (!src) {
        return (null);
    }

    return (
        <figure className={ classNames(className, 'shrink-0 overflow-hidden') }>
            <img className={ classNames('w-full', imageHeightClass, 'rounded object-cover') } src={ src } alt={ alt || 'Snapshot' } />
            <figcaption className="hidden">Snapshot</figcaption>
        </figure>
    );
};

const Card = function({ image, title, round, className, imageHeightClass, children }) {
    return (
        <article className={ classNames(className, 'flex flex-col group rounded') }>
            <Image className={ classNames(imageHeightClass, 'margin-0') } imageHeightClass={ imageHeightClass } src={ image } alt="Snapshot" />

            <div className="flex-grow py-2 px-4">
                { children }
            </div>
        </article>
    );
};


export default Card;
