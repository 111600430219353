import { useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppContext } from '../context/AppContext';
import { classNames } from '../styling';
import api from '../api';

import Modal from './Modal';


const Dev = function DevResponsive({ className, top=false, right=false, children }) {
    const context = useContext(AppContext);

    if (!context.dev) {
        return (null);
    }

    const cy = (top) ? 'top-6' : 'bottom-6';
    const cx = (right) ? 'right-2' : 'left-2';

    const width = 'w-auto';

    return (
        <section className="relative w-full z-50 text-xs">
            <div className={ classNames('fixed', cy, cx , width, 'rounded', className) }>
                <div className="flex gap-4">
                    { children }
                </div>
            </div>
        </section>
    );
};

/**
 * @see https://tailwindcss.com/docs/responsive-design
 * sm   640px   @media (min-width: 640px) { ... }
 * md   768px   @media (min-width: 768px) { ... }
 * lg   1024px  @media (min-width: 1024px) { ... }
 * xl   1280px  @media (min-width: 1280px) { ... }
 * 2xl  1536px  @media (min-width: 1536px) { ... }
 *
 * @see extensuons in ../../tailwind.config.js
 */
Dev.Responsive = function DevResponsive({ className }) {
    const prefixes = {
        'sm':        'bg-gray-200 sm:bg-blue-400 md:bg-gray-200 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-gray-200', // 640px  @media (min-width: 640px) { ... }
        'md':        'bg-gray-200 sm:bg-gray-200 md:bg-blue-400 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-gray-200', // 768px  @media (min-width: 768px) { ... }
        'lg':        'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-blue-400 xl:bg-gray-200 2xl:bg-gray-200', // 1024px @media (min-width: 1024px) { ... }
        'xl':        'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-gray-200 xl:bg-blue-400 2xl:bg-gray-200', // 1280px @media (min-width: 1280px) { ... }
        '2xl':       'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-blue-400', // 1536px @media (min-width: 1536px) { ... }

        'lgr':       'bg-gray-200 sm:bg-gray-200 md:bg-gray-200 lg:bg-gray-200 xl:bg-gray-200 2xl:bg-gray-200 lgr:bg-amber-700',
    };

    return (
        <div className={ classNames(className) }>
            {
                Object.keys(prefixes).map((key, index) => (
                    <span key={ index } className={ classNames('px-1 py-0 mr-1', prefixes[key]) }>{ key }</span>
                ))
            }
        </div>
    );
};

/**
 * @see https://tailwindcss.com/docs/responsive-design
 * sm   640px   @media (min-width: 640px) { ... }
 * md   768px   @media (min-width: 768px) { ... }
 * lg   1024px  @media (min-width: 1024px) { ... }
 * xl   1280px  @media (min-width: 1280px) { ... }
 * 2xl  1536px  @media (min-width: 1536px) { ... }
 *
 * @see extensions in ../../tailwind.config.js
 */
Dev.NavigateTo = function DevNavigateTo({ className }) {
    const navigate = useNavigate();
    const location = useLocation();

    const routeRef = useRef(location.pathname);

    const handleSubmit = (event) => {
        event.preventDefault();

        const route = routeRef.current.value;
        if(route) {
            navigate(route);
        }
    };

    return (
        <form
            className={ classNames(className) }
            onSubmit={ handleSubmit }
        >
            <input type="text" className="opacity-85 px-2 bg-reminu--light" placeholder="route..." ref={ routeRef } />
            <button type="submit" className="opacity-85 px-2 ml-2 bg-reminu--light">Browse</button>
        </form>
    );
};

Dev.Json = function({ title, className, data=null }) {

    title = title || 'data';
    const btnClassName = classNames('opacity-85 px-2 bg-reminu--light', className);

    if(!data) {
        return (
            <button
                className={ btnClassName }
                type="button"
                disabled
                onClick={ e => e.preventDefault() }
            >{ `${title}: (empty)` }</button>
        );
    }

    return (
        <Modal
            btnText={ title }
            title={ title }
            btnClassName={ btnClassName }
        >
            <pre className="">
                { JSON.stringify(data, null, 2) }
            </pre>
        </Modal>
    );
};


const ApiTest = function({ className }) {

    // define request choices here [endpoint, data].
    // setting data will trigger POST
    const requests = [
        [null, null], // init
        ['/auth/api/profile', null],
        ['/api/albums', null],
        ['/api/albums/0', null],
        ['/api/guidedplans', null],
        ['/api/guidedplans/0', null],
        // ..add more
    ];

    const [choice, setChoice] = useState(0);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (ev) => {
        ev.preventDefault();
        const { value } = ev.target;
        setChoice(value);

        const [endpoint, data] = requests[value];
        if(!endpoint) {
            setResponse(null);
            setLoading(false);
            return;
        }

        setLoading(true);

        api.request(endpoint, data)
        .then(res => {
            setResponse(res);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            setResponse(error);
        });
    };

    const [endpoint, data] = requests[choice];

    return (
        <form className={ className }>
            <div className="p-4 mb-2 border border-reminu--grey-6">
                <strong className="block mb-2">Request</strong>
                <select className="px-4 py-1" onChange={ handleChange }>
                {
                    requests.map((req, idx) => <option value={ idx }>{ req[0] }</option>)
                }
                </select>
                { data && (<pre>{ JSON.stringify(data) }</pre>) }
            </div>
            <div className="p-4 bg-reminu--grey-6">
                <strong className="block mb-2">Response</strong>
                { (loading) ? '' : `Endpoint: ${endpoint}` }
                <pre className="m-4">
                    { (loading) ? 'loading...' : JSON.stringify(response, null, 2) }
                </pre>
            </div>
        </form>
    );
};

Dev.ApiTest = function({ className }) {

    const title = 'api tests';
    const btnClassName = classNames('opacity-85 px-2 bg-reminu--light', className);


    return (
        <Modal
            btnText={ title }
            title={ title }
            btnClassName={ btnClassName }
        >
            <ApiTest />
        </Modal>
    );
};

export default Dev;
