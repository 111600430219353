import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import { routes } from '../navigation';


const Brand = function() {
    return (
        <Page.Simple>
            <PageTitle route={ routes.brand } />

            <div className="flex flex-row gap-4 mb-4">
                <div className="text-4xl text-center content-center justify-center w-80 h-80 text-reminu-purple-light bg-reminu-purple-dark"><strong><em>reminu</em></strong></div>
                <div className="text-4xl text-center content-center justify-center w-80 h-80 text-reminu-navy-light bg-reminu-navy-dark"><strong><em>reminu</em></strong></div>
                <div className="text-4xl text-center content-center justify-center w-80 h-80 text-reminu-forest-light bg-reminu-forest-dark"><strong><em>reminu</em></strong></div>
            </div>
            <div className="flex flex-row gap-4">
                <div className="text-4xl text-center content-center justify-center w-80 h-80 text-reminu-red-dark bg-reminu-red-light"><strong><em>reminu</em></strong></div>
                <div className="text-4xl text-center content-center justify-center w-80 h-80 text-reminu-blue-dark bg-reminu-blue-light"><strong><em>reminu</em></strong></div>
                <div className="text-4xl text-center content-center justify-center w-80 h-80 text-reminu-green-dark bg-reminu-green-light"><strong><em>reminu</em></strong></div>
            </div>
        </Page.Simple>
    );
};


export default Brand;
