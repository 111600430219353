import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { classNames, statusColors } from '../styling';

const Notification = function({ status, message, title, className }) {

    const [closed, setClosed] = useState(false);

    if(closed || !message) {
        return(null);
    }

    const colors = statusColors(status);

    return (
        <div className={ classNames(className, 'border-l-8 p-4 flex content-center', colors) } role="alert">
            <div className="grow"><strong className="capitalize mr-4">{ title || status }:</strong>{ message.toString() }</div>
            <div className="flex-none">
                <button className="ml-4 opacity-60 hover:opacity-100 " onClick={ () => setClosed(true) }>
                    <FontAwesomeIcon icon={ faXmark } />
                </button>
            </div>
        </div>
    );
};

const Alert = {};

Alert.Any = function AlertAny(props) {
    return (
        <Notification { ...props } />
    );
};

Alert.Error = function AlertError(props) {
    return (
        <Notification status="error" { ...props } />
    );
};

Alert.Info = function AlertInfo(props) {
    return (
        <Notification status="info" { ...props } />
    );
};


export default Alert;
