import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import { classNames, Styles } from '../styling';
import api from '../api';


const Checked = function({ checked, className }) {
    const icon = (checked) ? faCircleCheck : faCircleXmark;
    return (<FontAwesomeIcon className={ className } icon={ icon } />);
};

const UserEditForm = function({ user, className, notify }) {

    const [firstName, setFirstName] = useState((user) ? user.last_name : '');
    const [lastName, setLastName] = useState((user) ? user.first_name : '' );
    const [email, setEmail] = useState((user) ? user.email : '' );
    const [tags, setTags] = useState('test tag'); //(user) ? user.tags : null
    const [isCarer, setIsCarer] = useState((user) ? user.iscarer : '' );
    const [emailConsent, setEmailConsent] = useState((user) ? user.emailconsent : '' );

    const payload = function() {
        return {
            first_name: firstName,
            last_name: lastName,
            email,
            tags,
            iscarer: isCarer,
            emailconsent: emailConsent
        };
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const data = payload();
        return api.profile(data)
            .then(() => notify('info', 'Your profile has been updated'))
            .catch(error => notify('error', error));
    };

    const labelClass ='block text-sm';

    if (!user) {
        return (null);
    }

    return (
        <form className={ classNames(className) } onSubmit={ onSubmit }>

            <div className="mb-4">
                <div className="mb-2">
                    <label htmlFor="first_name" className={ labelClass }>
                        First name
                    </label>
                </div>

                <div className="mb-2">
                    <input
                        name="first_name"
                        type="text"
                        value={ firstName }
                        onChange={ e => setFirstName(e.target.value) }
                        className={ classNames('w-full', Styles.textInput) }
                    />
                </div>
            </div>

            <div className="mb-4">
                <div className="mb-2">
                    <label htmlFor="last_name" className={ labelClass }>
                        Last name
                    </label>
                </div>

                <div className="mb-2">
                    <input
                        name="last_name"
                        type="text"
                        value={ lastName }
                        onChange={ e => setLastName(e.target.value) }
                        className={ classNames('w-full', Styles.textInput) }
                    />
                </div>
            </div>

            <div className="mb-4">
                <div className="mb-2">
                    <label htmlFor="email" className={ labelClass }>
                        Email
                    </label>
                </div>

                <div className="mb-2">
                    <input
                        name="email"
                        type="email"
                        value={ email }
                        onChange={ e => setEmail(e.target.value) }
                        className={ classNames('w-full', Styles.textInput) }
                    />
                </div>
            </div>

             <div className="mb-4">
                <div className="mb-2">
                    <label htmlFor="email" className={ labelClass }>
                        Tags
                    </label>
                </div>
                    <textarea
                        name="tags"
                        value={ tags }
                        onChange={ e => setTags(e.target.value) }
                        className={ classNames('w-full h-32', Styles.textInput) }
                    />
            </div>

             <div className="mb-4">
                <button
                    name="tags"
                    className={ classNames('mr-2 mb-2', Styles.btnRounded) }
                    onClick={ (e) => {
                        e.preventDefault();
                        setIsCarer(!isCarer);
                    } }
                >
                    <Checked checked={ isCarer } /> Carer
                </button>
                <button
                    name="tags"
                    className={ classNames('mr-2', Styles.btnRounded) }
                    onClick={ (e) => {
                        e.preventDefault();
                        setEmailConsent(!emailConsent);
                    } }
                >
                    <Checked checked={ emailConsent } /> Consent to marketing
                </button>
            </div>

             <div className="mb-4">
                <button
                    type="submit"
                    name="submit"
                    className={ Styles.btnRoundedPrimary }
                >
                    Submit
                </button>
            </div>

        </form>
    );
};


export { UserEditForm as default, Checked };
