//// from: Home.jsx

// what is the latest entry exactly? a paragraph?
export const myLatestEntry = {
    id: 0,
    headline: 'Maecenas faucibus mollis interdum',
    content: [
        'Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Etiam porta sem malesuada magna mollis euismod.',
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur.',
    ].join('\n'),
    media: {
        mime: 'image/png',
        s3_url: 'assets/mocks/istockphoto-109434204-612x612 1.png',
        caption: 'Duis mollis, est non commodo luctus',
    }
};

export const myAlbums = [
    {
    id: 0,
    thumbnail: '/assets/mocks/holidays 1.png',
    title: 'Consectetur etiam adipiscing aenean pharetra',
}, {
    id: 1,
    thumbnail: '/assets/mocks/25bd51e5e98c4374d9b05df7a18cea79.jpeg',
    title: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor',
}, {
    id: 2,
    thumbnail: '/assets/mocks/3536d8492d72680fac034c6dc1c0edb2.jpeg',
    title: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam',
}];

export const myGuidedPlans = [{
    id: 0,
    thumbnail: '/assets/mocks/5af2fab4eec9dadac4bda59dd0409681.jpeg',
    title: 'Childhood guided plan',
}];

export const myNotifications = [{
    id: 0,
    thumbnail: '/assets/mocks/27a9197a6fa26e59b1b242219b85e21d.jpeg',
    user: 'Ryan Reynolds',
    action: 'commented on your memory',
    ago: () =>  '2 days ago',
}, {
    id: 1,
    thumbnail: '/assets/mocks/4db5bc1b96549aedc8b8fde9ebdfab0c.png',
    user: 'Gillian Anderson',
    action: 'liked your memory',
    ago: () => '1 week ago',
}];

///// from: AlbumPages.jsx
///// from: Album.jsx

export const mockAlbum = {
    id: 0,
    image: '/assets/default-album-image.png',
    name: 'Nullam quis risus eget urna mollis ornare vel eu leo',
    description: 'Nullam quis risus eget urna mollis ornare vel eu leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    is_active: true,
};

export const mockChapters = [{
    id: 0,
    image: '/assets/mocks/ca479c7717f2667b997810fce4283e77.png',
    name: 'Nulla vitae elit libero, a pharetra augue',
}, {
    id: 1,
    image: '/assets/mocks/images-1 1.png',
    name: 'Maecenas sed diam eget risus varius blandit',
}];

export const mockMoreAlbums = [{
    id: 1,
    image: '/assets/mocks/images-1 1.png',
    name: 'Aenean lacinia bibendum nulla sed consectetur',
    description: '',
    is_active: true,

}, {
    id: 2,
    image: '/assets/mocks/9357324596_d24517d744_z 1.png',
    name: 'Morbi leo risus, porta consectetur vestibulum at eros',
    description: '',
    is_active: true,
}];

export const mockAlbumPages = [{
    // simultaing new page for this demo (see layouts)
    id: 0,
    title: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
    subtitle: 'Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur.',
    image: '/assets/mocks/depositphotos_9757978-stock-photo-hawaiian-vacation-sunset-concept 2.png',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}, {
    id: 1,
    title: 'Donec sed odio dui.',
    subtitle: '',
    image: '',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}, {
    id: 2,
    title: 'Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus.',
    subtitle: '',
    image: '',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}, {
    id: 3,
    title: 'Nullam quis risus eget urna mollis ornare',
    subtitle: '',
    image: '',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}, {
    id: 4,
    title: 'Donec id elit non mi porta gravida at eget metus.',
    subtitle: '',
    image: '',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}, {
    id: 5,
    title: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
    subtitle: '',
    image: '',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}, {
    id: 6,
    title: 'Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac.',
    subtitle: '',
    image: '',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
}];

//// from: Albums.jsx
export const mockAlbums =
{
    "api_version": "0.1",
    "code": 200,
    "count": 19,
    "items": [
      {
        "created_by": 1,
        "created_on": "2023-07-10 00:33:18",
        "description": null,
        "id": 1,
        "image": null,
        "modified_by": 1,
        "modified_on": "2023-12-06 07:50:34",
        "name": "Life & Times",
        "user": {
          "email": "richard@behan-howell.com",
          "emailconsent": true,
          "first_name": "Richard",
          "id": 1,
          "iscarer": false,
          "last_name": "Behan-Howell",
          "tags": null
        }
      },
      {
        "created_by": 2,
        "created_on": "2023-07-11 05:43:58",
        "description": null,
        "id": 2,
        "image": null,
        "modified_by": 2,
        "modified_on": "2023-09-14 21:14:06",
        "name": "Scott\u2019s scrapbook",
        "user": {
          "email": "scottyoxford@gmail.com",
          "emailconsent": false,
          "first_name": "Scott",
          "id": 2,
          "iscarer": false,
          "last_name": "Oxford",
          "tags": "#intergen #future #values"
        }
      },
      {
        "created_by": null,
        "created_on": "2023-07-27 05:36:06",
        "description": null,
        "id": 6,
        "image": null,
        "modified_by": null,
        "modified_on": "2023-07-27 05:36:06",
        "name": "Life & Times",
        "user": {
          "email": "suzanne@newwordorder.com.au",
          "emailconsent": null,
          "first_name": "Suzanne",
          "id": 4,
          "iscarer": false,
          "last_name": "Oxford",
          "tags": null
        }
      }
    ],
    "status": "success",
    "timestamp": "2024-09-19T02:47:32.516042"
  };
export const oldmockAlbums = [{
    id: 0,
    image: '/assets/mocks/holidays-albums.png',
    name: 'Nullam quis risus eget urna mollis ornare vel eu leo',
    description: 'Nullam quis risus eget urna mollis ornare vel eu leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    is_active: true,
}, {
    id: 1,
    image: '/assets/mocks/san-diego-holiday-tours 2.png',
    name: 'Sed posuere consectetur est at lobortis',
    description: '',
    is_active: true,
}, {
    id: 2,
    image: '/assets/mocks/images-1 1.png',
    name: 'Aenean lacinia bibendum nulla sed consectetur',
    description: '',
    is_active: true,
}, {
    id: 3,
    image: '/assets/mocks/9357324596_d24517d744_z 1.png',
    name: 'Morbi leo risus, porta consectetur vestibulum at eros',
    description: '',
    is_active: true,
}];

//// from: AlbumPage.jsx

// postgres public.topic
export const mockAlbumPage = {
    id: 0,
    album: {
        id: 0,
    },

    // simultaing new page for this demo (see layouts)
    title: 'Maecenas faucibus mollis interdum',
    subtitle: '',
    image: '/assets/mocks/depositphotos_9757978-stock-photo-hawaiian-vacation-sunset-concept 2.png',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
};

// postgres public.paragraph
export const mockAlbumPageParagraphs = [{
    headline: '',
    content: '',
    pullquote: 'Donec ullamcorper nulla non metus auctor fringilla.',
}, {
    // postgres public.headline
    headline: '',
    content: '',
    pullquote: 'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
}];


///// from: AlbumPageLayout.jsx ("Mobile: New page layout")

export const mockPageLayoutPage = {
    id: 0,
    album: {
        id: 0,
    },
    // simultaing new page for this demo (see layouts)
    title: 'Maecenas faucibus mollis interdum',
    subtitle: 'Aenean lacinia bibendum nulla sed consectetur. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur.',
    image: '/assets/mocks/5cc306c04674972ae2324686cdcbb471165a0ee9-4400x2930.png',

    allowuploadimage: true,
    allowuploadvideo: true,
    allowuploadaudio: true,
    allowuploaddocument: true,
};

export const mockPageLayoutPageParagraphs = [{
    id: 0,
    album: {
        id: 0,
    },
    page: {
        id:0,
    },

    headline: '',
    content: 'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam',
    pullquote: '',
    media: [{
        mime: 'image/png',
        s3_url: '/assets/mocks/Rectangle 30.png',
        caption: 'Donec ullamcorper nulla non metus auctor fringilla.',
    }, {
        mime: 'image/png',
        s3_url: '/assets/mocks/Rectangle 31.png',
        caption: '',
    }],
}];

//// from: Profile.jsx

export const mockAlbumsMyself = [{
    id: 0,
    image: '/assets/mocks/ca479c7717f2667b997810fce4283e77.png',
    name: '',
    description: 'Nullam quis risus eget urna mollis ornare vel eu leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    is_active: true,
    shared: [{
        first_name: 'Ryan',
        last_name: 'Reynolds',
        image: '/assets/mocks/27a9197a6fa26e59b1b242219b85e21d.jpeg',
    }, {
        first_name: 'Gillian',
        last_name: 'Anderson',
        image: '/assets/mocks/4db5bc1b96549aedc8b8fde9ebdfab0c.png',
    }],
}, {
    id: 1,
    image: '/assets/mocks/28a9b15c5be33dbb75f8e9ad2add7e6a.jpeg',
    name: '',
    description: 'Aenean lacinia bibendum nulla sed consectetur. ',
    is_active: true,
    shared: [{
        first_name: 'Gillian',
        last_name: 'Anderson',
        image: '/assets/mocks/4db5bc1b96549aedc8b8fde9ebdfab0c.png',
    }],
}];

export const mockAlbumsOther = [{
    id: 0,
    image: '/assets/mocks/holidays-albums.png',
    name: '',
    description: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis.',
    is_active: true,
    shared: [{
        first_name: 'Gillian',
        last_name: 'Anderson',
        image: '/assets/mocks/4db5bc1b96549aedc8b8fde9ebdfab0c.png',
    }]
}, {
    id: 1,
    image: '/assets/mocks/istockphoto-109434204-612x612 1.png',
    name: '',
    description: 'Cum sociis natoque penatibus et magnis dis parturient montes.',
    is_active: true,
    shared: [{
        first_name: 'Ryan',
        last_name: 'Reynolds',
        image: '/assets/mocks/27a9197a6fa26e59b1b242219b85e21d.jpeg',
    }]
}];


//// GuidedPlan.jsx

export const mockGP =
{
    "api_version": "0.1",
    "code": 200,
    "count": 1,
    "items": [
      {
        "albumname": "Random",
        "audiofile": null,
        "chaptername": "Random",
        "desc": "Create a new page about anything",
        "guidedplan_prompt_items": [
          {
            "allowuploadaudio": true,
            "allowuploaddocument": true,
            "allowuploadimage": true,
            "allowuploadvideo": true,
            "chaptername": null,
            "guidedplan": 3,
            "help": "In brief terms describe who is involved and where...",
            "id": 4,
            "pagename": "@HEADLINE",
            "pagesubheading": "",
            "prompt": "Introduce the story",
            "promptasheading": false,
            "stepno": 2,
            "type": "PARAGRAPH"
          },
          {
            "allowuploadaudio": true,
            "allowuploaddocument": true,
            "allowuploadimage": true,
            "allowuploadvideo": true,
            "chaptername": null,
            "guidedplan": 3,
            "help": "Go into detail about the people involved, what they did and what the outcome was",
            "id": 5,
            "pagename": "@HEADLINE",
            "pagesubheading": "",
            "prompt": "Describe what happened in detail",
            "promptasheading": false,
            "stepno": 3,
            "type": "PARAGRAPH"
          },
          {
            "allowuploadaudio": true,
            "allowuploaddocument": true,
            "allowuploadimage": true,
            "allowuploadvideo": true,
            "chaptername": null,
            "guidedplan": 3,
            "help": "Share anything else that comes to mind, about the people, place, smell or how you feel?",
            "id": 6,
            "pagename": "@HEADLINE",
            "pagesubheading": "",
            "prompt": "Anything else",
            "promptasheading": false,
            "stepno": 4,
            "type": "PARAGRAPH"
          },
          {
            "allowuploadaudio": false,
            "allowuploaddocument": false,
            "allowuploadimage": false,
            "allowuploadvideo": false,
            "chaptername": null,
            "guidedplan": 3,
            "help": "A headline that describes this memory",
            "id": 7,
            "pagename": "@HEADLINE",
            "pagesubheading": "",
            "prompt": "Headline",
            "promptasheading": false,
            "stepno": 1,
            "type": "HEADLINE"
          }
        ],
        "guidedplan_user_items": [
          {
            "guidedplan": 3,
            "id": 23,
            "lastheadline": "Test Memory",
            "nextstepno": 1,
            "page": 41,
            "user": 12
          },
          {
            "guidedplan": 3,
            "id": 27,
            "lastheadline": "",
            "nextstepno": 2,
            "page": 47,
            "user": 14
          },
          {
            "guidedplan": 3,
            "id": 24,
            "lastheadline": "Story of pistachio nut",
            "nextstepno": 1,
            "page": 43,
            "user": 1
          },
          {
            "guidedplan": 3,
            "id": 25,
            "lastheadline": "dsdsdsd",
            "nextstepno": 3,
            "page": 61,
            "user": 2
          }
        ],
        "id": 3,
        "image": 5,
        "name": "Create a Page",
        "plan": null,
        "runthrough": true,
        "tags": "#generic"
      }
    ],
    "status": "success",
    "timestamp": "2024-09-28T06:16:51.350095"
  };
/*{
    // postgres: guidedplan
    guidedPlan: {
        id: 1, // 5 in db
        image: '/assets/mocks/guided-plan-1.png',
        name: 'Intergeneration teen life',
        desc: 'Etiam porta sem malesuada magna mollis euismod. Maecenas faucibus mollis interdum.',
    },
    // postgres: guidedplan_prompt
    prompts: function(id) {
        return [
            {
                id: 9,
                prompt: 'What hobbies did you enjoy at my age?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 10,
                prompt: 'What were the most challenging parts of being a teen?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 11,
                prompt: 'Who supported you when you were a teen?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 12,
                prompt: 'How did you get through some of your difficult times at my age?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 13,
                prompt: 'What was school like for you?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 14,
                prompt: 'Who were some of your best friends growing up?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 15,
                prompt: 'What were your grandparents and parents like?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 16,
                prompt: 'How did people find long-lasting friendships and relationships when you were my age?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 17,
                prompt: 'What did love look like for you at my age?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 18,
                prompt: 'How did you get through your first heartbreak?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 19,
                prompt: 'How did you gain confidence in your school and career decisions?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 20,
                prompt: 'How did you manage money and finances at my age?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            }, {
                id: 21,
                prompt: 'How did you choose your career? Did you end up staying in the job you thought you would have?',
                allowuploadimage: true,
                allowuploadvideo: true,
                allowuploadaudio: true,
                allowuploaddocument: true,
            },
        ];
    },
};*/

//// from: GuidedPlans.jsx

export const mockGuidedPlans =
{
    "api_version": "0.1",
    "code": 200,
    "count": 9,
    "items": [
      {
        "albumname": "Life & Times",
        "audiofile": null,
        "chaptername": "Earlier Life",
        "desc": "Relating to ones childhood and growing up.",
        "id": 1,
        "image": {
          "filename": "guidedplan-3.jpg",
          "filesize": "-1",
          "id": 3,
          "name": "guidedplan-3.jpg",
          "system_path": "/home/RichardBH/py4web_reminu/apps/_default/uploads/guidedplan-3.jpg",
          "table": "guidedplan",
          "web_path": "/downloadfile/guidedplan-3.jpg"
        },
        "image_url": null,
        "name": "Childhood",
        "plan": null,
        "runthrough": null,
        "tags": "#childhood #intergen"
      },
      {
        "albumname": "Life & Times",
        "audiofile": null,
        "chaptername": "Earlier Life",
        "desc": "First real job",
        "id": 2,
        "image": {
          "filename": "guidedplan-4.jpg",
          "filesize": "-1",
          "id": 4,
          "name": "guidedplan-4.jpg",
          "system_path": "/home/RichardBH/py4web_reminu/apps/_default/uploads/guidedplan-4.jpg",
          "table": "guidedplan",
          "web_path": "/downloadfile/guidedplan-4.jpg"
        },
        "image_url": null,
        "name": "First Job",
        "plan": null,
        "runthrough": null,
        "tags": "#work #firstjob"
      },
      {
        "albumname": "Random",
        "audiofile": null,
        "chaptername": "Random",
        "desc": "Create a new page about anything",
        "id": 3,
        "image": {
          "filename": "guidedplan-5.png",
          "filesize": "-1",
          "id": 5,
          "name": "guidedplan-5.png",
          "system_path": "/home/RichardBH/py4web_reminu/apps/_default/uploads/guidedplan-5.png",
          "table": "guidedplan",
          "web_path": "/downloadfile/guidedplan-5.png"
        },
        "image_url": null,
        "name": "Create a Page",
        "plan": null,
        "runthrough": true,
        "tags": "#generic"
      },
      {
        "albumname": "Intergeneration guided journey",
        "audiofile": null,
        "chaptername": "About me",
        "desc": "Intergeneration guided journey about me",
        "id": 4,
        "image": null,
        "image_url": null,
        "name": "About me",
        "plan": null,
        "runthrough": false,
        "tags": "#intergen #aboutme"
      },
      {
        "albumname": "Intergeneration Teen Life",
        "audiofile": null,
        "chaptername": "Teen Life",
        "desc": "Intergeneration teen life",
        "id": 5,
        "image": null,
        "image_url": null,
        "name": "Intergeneration teen life",
        "plan": null,
        "runthrough": true,
        "tags": "#intergen #teenlife"
      },
      {
        "albumname": "About Me",
        "audiofile": null,
        "chaptername": "About Me",
        "desc": "This is who I am",
        "id": 6,
        "image": null,
        "image_url": null,
        "name": "All about me",
        "plan": null,
        "runthrough": true,
        "tags": "#personality"
      },
      {
        "albumname": "My future",
        "audiofile": null,
        "chaptername": "Why I've created this album",
        "desc": "What I want people to know about my care and wishes for the future",
        "id": 7,
        "image": null,
        "image_url": null,
        "name": "My future wishes and needs",
        "plan": null,
        "runthrough": true,
        "tags": "#future"
      },
      {
        "albumname": "Self-awareness",
        "audiofile": null,
        "chaptername": "Values",
        "desc": "Increasing your self-awareness of your values.  Your values are the things that are important to you. They often take on short labels such as: family, friendships, education, personal or professional development.  There are many reasons why knowing your values can be important. Firstly, by explicitly having stated your values you can assess whether you are spending time and making decisions in accordance with what you find important.",
        "id": 8,
        "image": {
          "filename": "guidedplan-7.jpg",
          "filesize": "-1",
          "id": 7,
          "name": "guidedplan-7.jpg",
          "system_path": "/home/RichardBH/py4web_reminu/apps/_default/uploads/guidedplan-7.jpg",
          "table": "guidedplan",
          "web_path": "/downloadfile/guidedplan-7.jpg"
        },
        "image_url": null,
        "name": "Values",
        "plan": null,
        "runthrough": false,
        "tags": "#Values"
      }
    ],
    "status": "success",
    "timestamp": "2024-09-28T03:08:52.326187"
  };

/*
[{
    id: 0,
    image: '/assets/mocks/guided-plan-2.png',
    name: 'Elit sollicitudin ultricies',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.',
}, {
    id: 1,
    image: '/assets/mocks/guided-plan-2.png',
    name: 'Elit sollicitudin ultricies',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.',
}, {
    id: 2,
    image: '/assets/mocks/guided-plan-2.png',
    name: 'Elit sollicitudin ultricies',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.',
}, {
    id: 3,
    image: '/assets/mocks/guided-plan-2.png',
    name: 'Elit sollicitudin ultricies',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.',
}, {
    id: 4,
    image: '/assets/mocks/guided-plan-2.png',
    name: 'Elit sollicitudin ultricies',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.',
}, {
    id: 5,
    image: '/assets/mocks/guided-plan-2.png',
    name: 'Elit sollicitudin ultricies',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.',
}];
*/
export const mockFeaturedPlan = {
    id: 99,
    image: '/assets/mocks/guided-plan-1.png',
    name: 'Childhood',
    description: 'Etiam porta sem malesuada magna mollis euismod. Maecenas faucibus mollis interdum.',
};

export const mockFilters = {
    Filters: 'Filters',
    Mattis: 'Mattis',
    Sollicitudin: 'Sollicitudin',
    Mollis: 'Mollis',
};

//// from AuthContext

export const userStub = {
    fullName: '',
    thumbnail: '/assets/mocks/betty-white.jpg',
    tags: '',
};
