import api from './api';
import { gets } from './utils';

/**
 * Normalisers and getters for api response data.
 * Normalising is handled in an old-fashioned way in order to avoid side effects down the react chain
 * Bundled here for quick applying changes of the REST models
 *
 * Hopefully much of it will be obsolete when the backend api is stable
 */



const imagePath = function(item, _default='') {
    if (!item) {
        return _default;
    }

    const image = gets(item, 'image', {});

    // parse out image path
    // I expect the path property to be inconsistent, add more cases below

    let path = gets(image, 'path', '');
    if (!path) {
        path = gets(image, 'web_path', '');
    }

    return (path) ? api.url(path) : _default;
};

const prompts = function(guidedPlan) {
    return gets(guidedPlan, 'guidedplan_prompt_items',  []);
};

const items = function(response) {
    const items = gets(response, 'items',  []);
    return (Array.isArray(items)) ? items : [];
};

/**
 * gets first item from a response. workaround for missing single item responses
 *
 * @returns {object|null}
 *
 * py4web is weird, everything seems to be returned as a listing (execept... /auth/api)
 * This is weird and against common practice. It is not not even graphql conform.(https://graphql.org/learn/queries/)
*/
const firstItem = function(response) {
    const _items = items(response);
    return (_items.length) ? _items[0] : null;
};


const Models = {  imagePath , prompts, items, firstItem };

export default Models;
