import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { classNames, Styles } from '../styling';


const FilteredSearch = function({ filters, className, searchClassName, filterClass, textButton=true }) {
    filters = filters || {};

    const [selected, setSelected] = useState([]);

    const handleSelectFilter = function(e) {
        e.preventDefault();
        const value = e.target.value;

        const updated = selected.filter(item => item !== value);
        if (updated.length === selected.length) {
            // item not in filters: add
            updated.push(value);
        }
        setSelected(updated);
    };

    return (
        <div className={ className }>
            <Search className={ searchClassName } textButton filters={ selected } />

            <div className={ classNames(filterClass, 'inline-block') }>
            {
                Object.keys(filters).map((value, index) => (
                    <button
                        key={ index }
                        value={ value }

                        className={ classNames('mr-2 mt-1 mb-1', (selected.indexOf(value) === -1) ? 'bg-transparent' : 'bg-reminu--grey-6' , Styles.btnRoundedLight) }
                        onClick={ handleSelectFilter }
                    >
                        { filters[value] }
                    </button>
                ))
            }
            </div>

            { /* <pre>{ query } ({ selected.toString() })></pre> */ }
        </div>
    );
};


const Search = function({ className, textButton=false, filters={} }) {

    const handleSubmit = function(ev) {
        ev.preventDefault();
        return Promise.resolve(null); // mocK: TODO
    };

    const groupHover = 'hover:bg-reminu--grey-6 group-hover:bg-reminu--grey-6';

    return (
        <form
            onSubmit={ handleSubmit }
            className="group w-full"
        >

            <fieldset className={ classNames(className, 'flex w-full rounded-full border', groupHover) }>
                <input
                    type="search"
                    name="search"
                    placeholder={ (textButton) ? null : 'Search' }
                    className={
                        classNames(
                            'transition duration-200 ease-in-out bg-transparent',
                            'grow h-10 mx-5 px-5 text-sm focus:outline-none',
                            groupHover
                        )
                    }

                />

                <button type="submit" className={ classNames('mr-5 whitespace-nowrap', groupHover) }>
                {
                    (textButton) ?
                        <>
                            <FontAwesomeIcon icon={ faMagnifyingGlass } className="h-4 w-4 fill-current text-medium" /><span className="hidden md-inline"> Search</span>
                        </>
                        :
                        <FontAwesomeIcon icon={ faMagnifyingGlass } className="h-4 w-4 fill-current" />
                }
                </button>
            </fieldset>

        </form>
    );
};

export { Search as default, FilteredSearch };
