import { classNames, statusColors } from '../styling';


const Message = function({ status, className, children }) {
    return (
        <div className={ classNames(className, 'p-4 flex items-center justify-center', statusColors(status)) }>
            { children }
        </div>
    );
};

Message.Any = function MessageAny(props) {
    return (
        <Message { ...props } />
    );
};

Message.Error = function MessageError(props) {
    return (
        <Message status="error" { ...props } />
    );
};

Message.Info = function MessageInfo(props) {
    return (
        <Message status="info" { ...props } />
    );
};


export default Message;
