import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSquarePlus } from '@fortawesome/free-solid-svg-icons'; // pro: faPenCircle, faCirclePlus

import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Alert from '../components/Alert';
import Media from '../components/Media';
import Teaser from '../components/Teaser';

import { routes } from '../navigation';
import { classNames, Styles } from '../styling';
import api from '../api';

import{ myLatestEntry, myAlbums, myGuidedPlans, myNotifications } from '../mock-data';


// todo preloader (entries empty?)
// todo thumbnail hover icon
const Section = function({ route, adminPath, children }) {

    const { name, icon, path } = route;
    const id = name.replace(/\W/g,'-').toLowerCase();

    return (
        <section id={ `sb--${id}` } className="mb-6">
            <div className="flex mb-4 border-b border-reminu--grey-0 items-center">
                <h3 className={ classNames('grow text-x whitespace-nowrap', Styles.h2) }>
                    <FontAwesomeIcon icon={ icon } aria-hidden="true" className="pr-2" />{ name }
                </h3>
                {
                    (adminPath) && (
                        <div className="opacity-50">
                            <Link className="ml-2  whitespace-nowrap" to={ adminPath }>
                                <FontAwesomeIcon icon={ faSquarePlus } aria-hidden="true" className="pr-2" />Add
                            </Link>
                            <Link  className="ml-2 px-2 py-1 outline outline-1 outline-inherit rounded-full whitespace-nowrap" to={ path() }>
                                See all
                            </Link>
                        </div>
                    )
                }
            </div>
            { children }
        </section>
    );
}

const Entry = function ({ entry, className }) {
    return (
        <article className={ className }>
            {
                (entry) ?
                    <>
                        <Media media={ entry.media } />

                        <h2 className={ Styles.h2xl }>{ entry.headline }</h2>
                        <summary className="mb-2">
                            { entry.content }
                        </summary>

                        <p className="opacity-50"><Link to={ routes.albumPages.path(entry.id) }><FontAwesomeIcon icon={ faPen } aria-hidden="true" className="pr-2" /> Edit</Link></p>
                    </>
                    :
                    <div className="p-8">No entry found found</div>
            }
        </article>
    );
};

const Home  = function() {

    const [latestEntry, setLatestEntry] = useState(null);
    const [albums, setAlbums] = useState([]);
    const [guidedPlans, setGuidedPlans] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            api.mock(myLatestEntry),
            api.mock(myAlbums),
            api.mock(myGuidedPlans),
            api.mock(myNotifications),
        ])
        .then(([me, ma, mg, mn]) => {
            setLatestEntry(me);
            setAlbums(ma);
            setGuidedPlans(mg);
            setNotifications(mn);

            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            setError(error);
        });
    }, []);

    const thumbSize = 'size-14 md:size-20'; // rounded thumbnail sidebar

    return (
        <Page.Simple>
            <PageTitle route={ routes.home } />
            <Preloader loading={ loading } message={ null } bgColor="bg-transparent" fgColor="bg-reminu--light"  className="py-12" />
            <Alert.Error message={ error } />

            <IfLoaded loading={ loading }>
                <div className="flex flex-col md:flex-row gap-x-8 gap-y-4">

                    <section className="basis-2/3">
                        <p className="mb-2">Latest entry</p>
                        <Entry entry={ latestEntry } />
                    </section>

                    <aside className="basis-1/3">

                        <Section
                            route={ routes.albums }
                            adminPath={ routes.albumPages.path('new') }
                        >
                            <ul>
                                {
                                    albums.map((item, index) => (
                                        <li key={ index } className="mb-2">
                                            <Link to={ routes.albumPages.path(item.id) }>
                                                <Teaser.Rounded image={ item.thumbnail } size={ thumbSize }>
                                                    <h4>{ item.title }</h4>
                                                </Teaser.Rounded>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Section>

                        <Section
                            route={ routes.guidedPlans }
                            adminPath={ routes.guidedPlanEdit.path('new') }
                        >
                            <ul>
                                {
                                    guidedPlans.map((item, index) => (
                                        <li key={ index } className="mb-2">
                                            <Link to={ routes.guidedPlanEdit.path(item.id) }>
                                                <Teaser.Rounded image={ item.thumbnail } size={ thumbSize }>
                                                    <h4>{ item.title }</h4>
                                                </Teaser.Rounded>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Section>

                        <Section
                            route={ routes.notifications }
                            entries={ notifications }
                        >
                            <ul>
                                {
                                    notifications.map((item, index) => (
                                        <li key={ index } className="mb-2">
                                            <Link to={ routes.notification.path(item.id) }>
                                                <Teaser.Rounded image={ item.thumbnail } size={ thumbSize }>
                                                    <strong>{ item.user }</strong> <span className="opacity-50">{ item.action }</span> <span className="opacity-50">({ item.ago() })</span>
                                                </Teaser.Rounded>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Section>

                    </aside>
                </div>
            </IfLoaded>
        </Page.Simple>
    );
};


export default Home;
