import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'; // pro: faCirclePlus

import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import Webcam from '@uppy/webcam';
import Audio from '@uppy/audio';
import XHR from '@uppy/xhr-upload';

import { classNames } from '../styling';
import { listMedia } from '../media';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';


/**
 * creates and configures an Upp (core) instance
 * @param {Object[]} allowedMedia array of media objects (@see ../media.js')
 *
 * @see https://uppy.io/docs/
 */
const uppyInit = function(endpoint, allowedMedia=[]) {

    const options= {};
    let allowedFileTypes = [];

    const uppy = new Uppy(options);
    uppy.use(XHR, {
        endpoint,
        // @see api.js using session cookie auth
        async onBeforeRequest(xhr) {
            xhr.withCredentials = true;
        },
    });

    allowedMedia.forEach((medium) => {
        switch (medium.key) {
            case 'video':
                uppy.use(Webcam);
            break;
            case 'audio':
                uppy.use(Audio);
            break;
            default:
                // nothing
        }
        allowedFileTypes = allowedFileTypes.concat(medium.allowedFileTypes);
    });

    // update options
    uppy.setOptions({ allowedFileTypes });

    return uppy;
};

const ButtonInner = function ({ allowed=[], appearance='simple' }) {
    if (appearance === 'decorated') {
        return (
            <>
                <FontAwesomeIcon icon={ faSquarePlus } aria-hidden="true"  className="mr-2" /><span className="mr-2">Insert...</span>
                {
                    allowed.map((item, index) => <FontAwesomeIcon key={ index } className="mr-2" icon={ item.icon } />)
                }
            </>
        );
    }

    return ( <FontAwesomeIcon icon={ faSquarePlus } aria-hidden="true" /> );
};

/**
 *
 * @param {object} allowedMedia a filtered map of 'supportedMedia' (upload.js) { <api_field>: <human_readable> }
 *
 * @see https://uppy.io/docs/react/
 * @see https://github.com/transloadit/uppy/tree/main/packages/%40uppy/react
 */
const MediaUpload = function({ className, endpoint, allowedMedia=[], media={}, appearance='simple' }) {

    // IMPORTANT: passing an initializer function to prevent Uppy from being reinstantiated on every render.
    const allowed = listMedia(allowedMedia);

    const [uppy] = useState(uppyInit(endpoint, allowed));
    const [open, setOpen] = useState(false);

    // examples from docs, commented out because triggering "Uncaught Error: Maximum update depth exceeded..." inside UppyDashboard component
    // const fileCount = useUppyState(uppy, (state) => Object.keys(state.files).length);
    // const totalProgress = useUppyState(uppy, (state) => state.totalProgress);
    //
    // Also possible to get the state of all plugins.
    // const plugins = useUppyState(uppy, (state) => state.plugins);
    //
    // const [results, clearResults] = useUppyEvent(uppy, 'transloadit:result');
    // useUppyEvent(uppy, 'cancel-all', clearResults);

    if(!allowed.length) {
        return (null);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <button
                type="button"
                onClick={ () => setOpen(true) }
                className={ classNames(className, 'block flex items-center', (appearance === 'simple') ? 'justify-center' : 'justify-start') }
            >
                <ButtonInner
                    allowed={ allowed }
                    appearance={ appearance }
                />
            </button>

            <DashboardModal
                uppy={ uppy }
                open={ open }
                onRequestClose={ handleClose }
            />
        </>
    );

};


export default MediaUpload;
