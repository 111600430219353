import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import Teaser from '../components/Teaser';

import IfLoaded from '../components/IfLoaded';
import If from '../components/If';
import Alert from '../components/Alert';
import Message from '../components/Message';

import { routes } from '../navigation';
import { classNames, Styles } from '../styling';

import api from '../api';
import models from '../models';

import { FilteredSearch } from '../components/Search';
import { mockGuidedPlans, mockFeaturedPlan, mockFilters } from '../mock-data';


const Wizard = function({ className, title,  children }) {
    if (!title) {
        return (null);
    }

    return (
        <aside className={ classNames('block', className) }>
            <h3 className="mb-4 uppercase font-bold">{ title }</h3>
            <div>{ children }</div>
        </aside>
    );
};

const GuidedPlans = function() {
    const [guidedPlans, setGuidedPlans] = useState(null);
    const [filters, setFilters] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            api.guidedPlans(),
            api.mock(mockFilters)
        ])
            .then(([ps, fs]) => {
                setGuidedPlans(ps);
                setFilters(fs);

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setError(error);
            });
    }, []);

    // grid
    const grid = 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4';
    const column = '';
    const columnXl = 'hidden md:block xl:col-span-2';

    // w-32:  8rem, 128px
    // w-52: 13rem, 208px
    // w-40: 10rem; 160px
    // w-64: 16rem, 256px
    // w-80: 20rem, 320px

    // top teaser (height, image width)
    const topHeight = 'h-auto max-h-64 min-h-40 md:min-h-52';
    const topWidth = 'w-full md:w-32 lg:w-80';

    // bottom teasers (height, image width)
    const bottomHeight = 'h-32 min-h-32 md:h-52 md:min-h-52';
    const bottomWidth = 'w-auto md:w-auto max-w md:max-w-52';

    // img size, especially if src url == 404
    const topSize = classNames('w-full max-h-32 md:min-w-64 md:min-h-64', topWidth, topHeight);
    const bottomSize = classNames('min-w-32 min-h-32', bottomWidth, bottomHeight);

    const items = models.items(guidedPlans);
    const featured = (items.length) ? items[0] : { id: -1 };

    return (
        <Page.Simple>
            <PageTitle route={ routes.guidedPlans } />
            <Preloader loading={ loading } message="fetching contents" />
            <Alert.Error message={ error } />

            <IfLoaded loading={ loading }>
                <If.True test={ featured.id > -1 }>
                    <section className={ grid }>

                        <Teaser.Default
                            image = { models.imagePath(featured, '/assets/default-guidedplan-image_50.png') }
                            className={ classNames(column, 'bg-reminu--grey-2 group') }
                            size={ topSize }
                        >
                            <div className="block w-full h-full flex flex-col">
                                <div className="grow">
                                    <h2 className={ classNames(Styles.h2, 'md:text-base md:font-bold') }>{ featured.name }</h2>
                                    <summary className="mb-2">
                                        { featured.desc }
                                    </summary>
                                </div>
                                <div className="mb-4">
                                    <Link className={ classNames(Styles.btnRoundedSmall, 'hover:bg-reminu--light') } to={ routes.guidedPlanEdit.path(featured.id) }>Start</Link>
                                </div>
                            </div>
                        </Teaser.Default>

                        <Wizard title={ (featured) ? 'Wizard heading' : '' } className={ classNames(columnXl, topHeight, 'p-8 overflow-auto') }>
                            Nullam quis risus eget urna mollis ornare vel eu leo. Vestibulum id ligula porta felis euismod semper.
                            Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                        </Wizard>
                    </section>
                </ If.True>

                <If.True test={ items.length > 0 }>
                    <FilteredSearch
                        className={ classNames(grid, 'col-span-3') }
                        searchClassName="border-reminu--dark"
                        textButton
                        filters={ filters }
                    />
                </ If.True>

                <If.False test={ items.length > 0 }>
                    <Message.Any className="m-8 h-32">
                        <p><strong>Sorry!</strong> No guided plans have been set up yet.</p>
                    </Message.Any>
                </ If.False>

                <ul className={ grid }>
                {
                    items.map((entry, index) => (
                        <li key={ index } className={ (featured && entry.id === featured.id) ? 'hidden' : 'flex' }>
                            <Teaser.Strict
                                image = { models.imagePath(entry, '/assets/default-guidedplan-image_50.png') }

                                className={ classNames(column, bottomHeight, 'w-full bg-reminu--grey-6 group') }
                                size={ bottomSize }
                            >
                                <div className="block w-full h-full flex flex-col">
                                    <div className="grow">
                                        <h2 className={ classNames(Styles.h2, 'md:text-base md:font-bold') }>{ entry.name }</h2>
                                        <summary className="mb-2 hidden lg:block">
                                            { entry.desc }
                                        </summary>
                                    </div>
                                    <div className="mb-4">
                                        <Link className={ classNames(Styles.btnRoundedSmall, 'hover:bg-reminu--light') } to={ routes.guidedPlanEdit.path(entry.id) }>Start</Link>
                                    </div>
                                </div>
                            </Teaser.Strict>
                        </li>
                    ))
                }
                </ul>
            </IfLoaded>
        </Page.Simple>
    );
};


export default GuidedPlans;
