import { useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons';

import MediaUpload from './MediaUpload';

import Alert from '../components/Alert';
import Preloader from '../components/Preloader';

import { classNames, Styles } from '../styling';
import { timeout } from '../utils';

import api from '../api';
import { getAllowedMediaNames } from '../media';

const initUploads = () => [null, null, null, null];


/**
 * guided plan prompt form
 */
const PromptForm = function({ guidedPlan, prompt, className }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState(null);

    const [answer, setAnswer] = useState('');
    const uploads = initUploads(); // TODO

    const paragraphRef = useRef();

    const notify = function(status, message, millisec=0) {
        setMessage(message);
        setStatus(status);

        if (millisec > 0) {
            return timeout(() => {
                setMessage(null);
                setStatus(null);
            }, millisec);
        }

        return Promise.resolve(true);
    };

    const finalize = function(status, message, millisec=0) {
        setLoading(false);
        notify(status, message, millisec);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        // build data
        const prompt_id = prompt.id || 'new';
        const plan_id = guidedPlan.id;
        const { chaptername, pagename } = guidedPlan;

        const paragraph = paragraphRef.current.value;
        const media = []; //TODO

        const data = {
            id: prompt_id,
            guidedplan: plan_id,
            chaptername,
            pagename,
            paragraph,
        };

        api.guidedPlanPromptJson(prompt_id, data, media)
            .then(() => finalize('info', 'content updated'))
            .catch(error => finalize('error', error));
        //api.mock('TODO')
        //    .then(() => finalize('info', 'content updated'))
        //    .catch(error => finalize('error', error));
    };

    if (!guidedPlan || !prompt) {
        return (null);
    }

    const width = 'w-full md:w-5/6'; // 83.333333%
    const taHeight = 'h-96';  // 384px
    const uDim =  'w-full min-w-32 h-20 min-h-20'; // 128px x 80px

    const mediaKeys = getAllowedMediaNames(prompt);

    const prompt_id = prompt.id || 'new';
    const plan_id = guidedPlan.id;

    return(
        <form className="w-full" onSubmit={ onSubmit }>
            <Preloader loading={ loading } message="updating content" />
            <Alert.Any status={ status } message={ message } />

            <h2 className={ Styles.h2 }>{ prompt.prompt }</h2>

            <div className={ width }>
                <fieldset className="mb-4">
                    <textarea
                        className={ classNames('mt-2 w-full', taHeight, Styles.textInput) }
                        defaultValue={ answer }
                        ref={ paragraphRef }
                        onChange={ e => setAnswer(e.target.value) }
                        placeholder="type here..."
                    />
                </fieldset>

                <fieldset className="mb-4">
                    <p>{ `Do you have any ${mediaKeys.join(', ')}, you want to share?` }</p>
                </fieldset>

                <fieldset className="flex gap-4 justify-between align-center mb-8">
                    {
                        uploads.map((upload, index) => (
                            <div key={ index }>
                                <MediaUpload
                                    media={ upload }
                                    endpoint={ api.mediaUploadEndpoints.guidedPlanPromptJson(plan_id, prompt_id) }
                                    allowedMedia={ mediaKeys }
                                    className={ classNames(uDim, Styles.btnLight) }
                                    appearance="simple"
                                />
                            </div>
                        ))
                    }
                </fieldset>

                <fieldset>
                    <button
                        type="submit"
                        className={ classNames('font-bold', Styles.btnRounded) }
                    >
                        <FontAwesomeIcon icon={ faCircleRight } /> Save and next
                    </button>
                </fieldset>
            </div>
        </form>
    );
};


export default PromptForm;
