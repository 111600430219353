import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCircleRight, faSquarePlus, faBook, faPen } from '@fortawesome/free-solid-svg-icons'; // pro: fa-light: faCircleArrowDown fa-books

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Teaser from '../components/Teaser';
import Image from '../components/Image';
import Card from '../components/Card';

import { routes } from '../navigation';
import { Styles, classNames } from '../styling';
import api from '../api';

import { mockAlbumPages, mockMoreAlbums, mockChapters, mockAlbum } from '../mock-data'


const route = routes.albums; // TODO path

const ChapterLink = function ({ chapter, className, imgWidth, imgHeight, isActive, setActiveChapter }) {

    if (!chapter) {
        return (null);
    }

    const cls = {
        wrapper: (isActive) ? 'text-reminu--darker font-bold bg-reminu--grey-5' : 'text-reminu--grey-1 group hover:bg-reminu--grey-5 hover:cursor-pointer',
        button: (isActive) ? 'visible' : 'invisible',
    }

    return (
        <div
            className={ classNames(className, cls.wrapper, imgHeight, 'block w-full flex items-center') }
            onClick={ setActiveChapter }
        >
            <Image src={ chapter.image } className={ classNames(imgWidth, imgHeight, 'mr-4') } />
            <div className="flex flex-row w-full justify-between items-center">
                <p className="block w-4/5 text-ellipsis overflow-hidden">{ chapter.name }</p>
                <FontAwesomeIcon className={ classNames(cls.button, 'hidden md:block block z-10 group-hover:visible') } style={{ marginRight: '-0.5em' }} icon={ faCircleRight } />
            </div>
        </div>
    );
};

const PageLink = function ({ page, imgWidth, imgHeight }) {
    if (!page) {
        return (null);
    }

    return (
        <Link
            className={ classNames(imgHeight, 'block w-full flex items-center rounded hover:bg-reminu--grey-6 hover:cursor-pointer') }
            to={ routes.albumPageEdit.path(0, 0) }
        >
            {
                (page.image) ? <Image src={ page.image } className={ classNames(imgWidth, imgHeight, 'mr-4') } /> : <div className={ classNames(imgWidth, imgHeight, 'rounded mr-4 bg-reminu--grey-3') }></div>
            }
            <div>
                { page.title }
            </div>
        </Link>
    );
};

const TopSection = function({ className, album, moreAlbums=[] }) {
    if (!album) {
        return(null);
    }

    const teaserImgWidth = 'w-12/12 md:w-6/12'; // md:50%
    const teaserImgWidthHalf = 'w-6/12 md:w-3/12'; // md:25%
    const teaserImgHeight = 'h-full';
    const teaserContentWidth = teaserImgWidth; // md:50%

    const teaserBorder = 'border border-reminu--dark md:border-none';

    const cardWidth = 'w-52'; // 208px
    const cardHeight = 'h-60'; // 240px
    const cardimgHeight = 'h-28' // 112px

    const teaserHeight = cardHeight;

    return (
        <section className={ classNames(className, 'grid grid-cols-3') }> { /* no gap, see layouts */ }

            <div className="bg-reminu--light col-span-3 md:col-span-1 md:mr-8">

                <Teaser.Default
                    className={ classNames(teaserHeight, 'bg-reminu--grey-5', teaserBorder) }
                    image={ album.image }
                    size={ classNames(teaserImgWidth, teaserImgHeight) }
                    inner={ classNames('py-4 px-4 md:py-0', teaserContentWidth) }
                >
                    <p>{ album.name }</p>
                    <Link to={ routes.albumEdit.path(album.id) }>
                        <FontAwesomeIcon icon={ faPen } aria-hidden="true" className="pr-2" /> Edit Album
                    </Link>
                </Teaser.Default>

                <div className={ classNames(teaserImgWidthHalf, 'hidden md:block mt-8 text-right') }>
                    <FontAwesomeIcon icon={ faCircleArrowDown } aria-hidden="true" />
                </div>
            </div>

            <div className="hidden md:flex col-span-2 bg-reminu--light">
                <ul className="flex flex-row gap-4">
                    {
                        moreAlbums.map((entry, index) => (
                            <li key={ index } className="rounded-lg">
                                <article>
                                    <Link to={ routes.albumPageEdit.path(entry.id, 0) } >
                                        <Card
                                            className={ classNames(cardWidth, cardHeight, 'bg-reminu--grey-5 hover:bg-reminu--grey-3') }
                                            imageHeightClass={ cardimgHeight }
                                            image={ entry.image }
                                        >
                                            { entry.name }
                                        </Card>
                                    </Link>
                                </article>
                            </li>
                        ))
                    }

                    <li>
                        <Link to={ routes.albumEdit.path('new') } >
                            <Card
                                className={
                                    classNames(
                                        cardWidth, cardHeight,
                                        'bg-reminu--light text-reminu--grey-5',
                                        'border border-bg-reminu--grey-5',
                                        'hover:bg-reminu--grey-5 hover:text-reminu--light'
                                    )
                                }
                            >
                                <div className="flex flex-row h-full justify-center items-center">
                                    <FontAwesomeIcon className="text-3xl" icon={ faSquarePlus } aria-hidden="true" />
                                </div>
                            </Card>
                        </Link>
                    </li>
                </ul>
            </div>

        </section>
    );
};

const BottomSection = function({ className, album, activeChapter, setActiveChapter, setError, chapters=[], pages=[] }) {

    const [loading, setLoading] = useState(false);

    if (!album) {
        return(null);
    }

    const activeChapterId = (activeChapter) ? activeChapter.id : -1;

    const cImgWidth = 'w-28 min-w-28'; // 112px
    const cImgHeight = 'h-20'; // 80pxI

    const blockMargin = 'my-6 md:my-0';
    const blockLeftPadding = 'p-0 md:py-6 md:pl-6 md:pr-0'; // left column without right padding (large), @see Styles.pagePadding

    const responsiveChapters = (always=true, more ='') => (always) ? classNames('block', more) : classNames('hidden md:block', more);

    const selectActiveChapter = (id) => {
        setLoading(true);
        return api.mock(mockChapters)
            .then((data) => {
                setActiveChapter(data);
                setLoading(false);
            })
            .then((error) => {
                setLoading(false);
                setError(error);
            });
    };

    return (
        <section className={ classNames(className, 'grid grid-cols-3') }> { /* no gap, see layouts */ }
            <div className={ classNames(blockLeftPadding, blockMargin, 'col-span-3 md:col-span-1', 'md:mr-8 md:bg-reminu--grey-6') }>

                <h3 className={ Styles.h2 } style={{ marginTop: '0' }}><FontAwesomeIcon icon={ faBook } aria-hidden="true" /> Chapters</h3>
                <ul className="mt-4">
                    {
                        chapters.map((item, index) => (
                            <li key={ index } className={ responsiveChapters(index === 0, 'mb-4') }>
                                <ChapterLink
                                    chapter={ item }
                                    isActive={ item.id === activeChapterId }
                                    setActiveChapter={ () => selectActiveChapter(item.id) }
                                    imgWidth={ cImgWidth }
                                    imgHeight={ cImgHeight }
                                />
                            </li>
                        ))
                    }
                    <li className={ responsiveChapters(false) }>
                        <Link to={ routes.albumChapter.path(0, 'new') } >
                            <div className={
                                classNames(
                                    cImgWidth, cImgHeight,
                                    'flex flex-row justify-center items-center',
                                    'rounded text-reminu--grey-3 border border-reminu--grey-3',
                                    'hover:bg-reminu--grey-5'
                                )
                            }>
                                <FontAwesomeIcon className="text-3xl" icon={ faSquarePlus } aria-hidden="true" />
                            </div>
                        </Link>
                    </li>
                </ul>

            </div>

            <div className={ classNames(Styles.blockLeftPadding, blockMargin, 'col-span-3 md:col-span-2 md:bg-reminu--light') }>

                <h3 className={ Styles.h2 } style={{ marginTop: '0' }}><FontAwesomeIcon icon={ faSquarePlus } aria-hidden="true" /> Pages</h3>
                <Preloader loading={ loading } message="fetching chapters" />
                <IfLoaded loading={ loading }>
                    <ul className="mt-4">
                        {
                            pages.map((item, index) => (
                                <li key={ index } className="mb-4">
                                    <PageLink
                                        page={ item }
                                        imgWidth={ cImgWidth }
                                        imgHeight={ cImgHeight }
                                    />
                                </li>
                            ))
                        }
                        <li>
                            <Link to={ routes.albumPageEdit.path(123, 'new') } >
                                <div className={
                                    classNames(
                                        cImgWidth, cImgHeight,
                                        'flex flex-row justify-center items-center',
                                        'rounded text-reminu--grey-3 border border-reminu--grey-3',
                                        'hover:bg-reminu--grey-5'
                                    )
                                }>
                                    <FontAwesomeIcon className="text-3xl" icon={ faSquarePlus } aria-hidden="true" />
                                </div>
                            </Link>
                        </li>
                    </ul>
                </IfLoaded>

            </div>
        </section>
    );
};


/**
 * Example of conditional usage, determined by viewport width:
 *
 * <pre>
 *      // ...
 *      import { Link, useNavigate } from 'react-router-dom';
 *      import useWindowDimensions from '../hooks/useWindowDimensions';
 *      // ...
 *
 *      const AlbumPages = function() {
 *          const { height, width } = useWindowDimensions();
 *          const navigate = useNavigate();
 *          // ...
 *
 *          const handleSetActiveChapter = (chapter) => {
 *              // Either load in-page component or navigate to chapter view
 *              //
 *              // @see https://tailwindcss.com/docs/responsive-design
 *              // md   768px   @media (min-width: 768px) { ... }
 *              //
 *              // TODO the layouts split the chapters view for mobiles into a new page, whilst integrate is for desktop
 *              //  -  this is tooo complex and prone to break inwith furure updates: solution handle desktop like mobile
 *
 *              // console.log(width, height, chapter);
 *              setActiveChapter(chapter);
 *
 *              if (width < 768) {
 *                  navigate(routes.albumChapter.path(0, 0));
 *              }
 *          };
 *          // ...
 *      };
 * </pre>
 */
const AlbumPages = function() {
    const  { album_id, page_id } = useParams();

    const [album, setAlbum] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [activeChapter, setActiveChapter] = useState(null);
    const [pages, setPages] = useState([]);

    const [moreAlbums, setMoreAlbums] = useState([]); // TODO mv in own component

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            api.mock(mockAlbum),
            api.mock(mockMoreAlbums),
            api.mock(mockChapters),
            api.mock(mockAlbumPages),
        ])
        .then(([a, ma, c, p]) => {
            setAlbum(a);
            setMoreAlbums(ma);
            setChapters(c);
            setActiveChapter((c && c.length) ? c[0] : null);
            setPages(p);

            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            setError(error);
        });
    }, [album_id, page_id]);

    return (
        <Page.Plain className="h-full">
            <Preloader loading={ loading } message="fetching contents" />
            <Alert.Error message={ error } />

            <IfLoaded loading={ loading }>
                <div className={ classNames(Styles.pagePadding, 'h-full flex flex-col') }> { /* wrapper */ }

                    <PageTitle route={ route } />
                    <TopSection
                        className={
                            classNames(
                                'h-auto flex-none',
                                'border-b border-transparent md:border-reminu--dark',
                                Styles.pagePaddingB
                            )
                        }
                        album={ album }
                        moreAlbums={ moreAlbums }
                    />

                    <BottomSection
                        className={
                            classNames(
                                'grow',
                                Styles.pagePaddingT,
                                Styles.pagePaddingB
                            )
                        }
                        album={ album }

                        chapters={ chapters }
                        activeChapter={ activeChapter }
                        setActiveChapter={ setActiveChapter }
                        setError={ setError }

                        pages={ pages }
                    />

                </div>{ /*  end wrapper */ }
            </IfLoaded>
        </Page.Plain>
    );
};


export default AlbumPages;
