/**
 * @module: handles backend media upload
 * This is currently only intended to dealing with or create MOCK data
 *
 * @see https://transloadit.com/docs/
 * @see postgres: table media_upload
 */
import { faImage, faVideo, faMusic, faFile } from '@fortawesome/free-solid-svg-icons';

import { gets, uuidv4 } from './utils';


const Settings = {


    transLoadIt: {
        waitForEncoding: true,
        alwaysRunAssembly: false,
        params: {
            // To avoid tampering use signatures:
            // https://transloadit.com/docs/api/#authentication
            auth: {
                key: '<insrted by backend ',
            },
            // It's often better store encoding instructions in your account
            // and use a `template_id` instead of adding these steps inline
        steps: {
            ':original': {
            robot: '/upload/handle',
            },
            exported: {
            use: ':original',
            robot: '/digitalocean/store',
            result: true,
            credentials: 'recordaDO',
            path: "media/${file.id}.${file.ext}"
            },
        },
        },
    }
};

// @see postgres: table media_upload
const mediaModel = function (obj={}) {

    return  Object.assign({}, obj, {
        id: -1,
        uniqueid: '',
        mime: '',
        ext: '',
        meta: '',
        ssl_url: '',
        title: '',
        caption: '',
        topic: -1,
        row: -1,
        created_on: 0,
        created_by: -1,
        modified_on: 0,
        modified_by: -1,
        is_active: 'T',
        paragraph: -1,
        s3_url: '',
    });

};

const createMediaModel = function (mime, s3_url) {
    const now = Math.floor(Date.now() / 1000);

    return mediaModel({
        mime,
        s3_url,

        uniqueid: uuidv4(),
        created_on: now,
        modified_on: now,
    })
};

/**
 * @see https://uppy.io/docs/uppy/#restrictions
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 *
 * TODO this should be defined in backedn and supplied to fronend via api
 */
const AllowImage = ['image/*'];
const AllowVideo = ['video/*'];
const AllowAudio = ['audio/*'];
const AllowDocument = ['.pdf', '.doc', '.abw', '.doc', '.docx', '.epub', '.csv', '.xls', '.xlsx', '.ppt', '.pptx', '.rtf', '.txt' ];

/**
 * @see postgres: public.guidedplan_prompt
 */
const Media = {
    image: {
        key: 'image',
        name: 'Images',
        icon: faImage,
        allowedFileTypes:  AllowImage,
    },
    video: {
        key: 'video',
        name: 'Videos',
        icon: faVideo,
        allowedFileTypes: AllowVideo,
    },
    audio: {
        key: 'audio',
        name: 'Audio',
        icon: faMusic,
        allowedFileTypes: AllowAudio,
    },
    document: {
        key: 'document',
        name: 'Documents',
        icon: faFile,
        allowedFileTypes: AllowDocument,
    },
};

/**
 * The media type name can be singular or plural, or a known database field name.
 */
const getMedium = function(type) {
    if(!type) {
        return null;
    }
    const rex = new RegExp('allowupload');
    const normalised = type.replace(rex, '').toLowerCase();
    if (typeof Media[normalised] !== 'undefined') {
        return Media[normalised];
    }
    return null;
};

/**
 * Compile an array of upload media settings from a list of names.
 * The media name can be singular or plural, or a known database field name.
 */
const listMedia = function(types=[]) {
    const list = [];

    types.forEach(function(type) {
        const medium = getMedium(type);
        if (medium) {
            list.push(medium);
        }
    });

    return list;
}

/**
 * Return a list of media keys for a given (api, db) record.
 * This function deals with expected inconsistencies in the field names during development
 *
 * @returns {string[]}
 */
const getAllowedMediaNames = function(record=null) {
    const list = [];

    if(!record) {
        return list;
    }

    Object.keys(Media).forEach((key) => {
        let has = gets(record, key, false);
        if(!has) {
            has = gets(record, `allowupload${key}`, false);
        }
        // insert other alternative cases here
        if (has) {
            list.push(key);
        }
    });

    return list;
}


export { mediaModel, createMediaModel, getMedium, listMedia, getAllowedMediaNames};
