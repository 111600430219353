import Image from './Image';
import { classNames } from '../styling';


const Teaser = {};

/**
 * Flexible two column teaser with a rectangular image (left). On small screens the columns fall in to rows
 */
Teaser.Default = function DefaultTeaser({ image, title, className, size, inner='py-4 px-4 md:py-0', overflow='overflow-auto', cover='object-cover object-center', children }) {
    const artc = classNames(className, 'flex flex-col md:flex-row items-center rounded-lg', overflow);
    const imgc = classNames(size, 'rounded-lg', cover);

    return (
        <article className={ classNames(artc, overflow) }>
            <Image className={ imgc } src={ image } alt="Snapshot" />
            <div className={ inner }>
                { children }
            </div>
        </article>
    );
};

/**
 * Strict two column teaser with a rectangular image (left).
 * It also stay always in two columns
 */
Teaser.Strict= function StrictTeaser({ image, title, className, size, inner='px-4', overflow='overflow-auto', cover='object-cover object-center', children }) {
    const artc = classNames(className,  'flex flex-row items-center rounded-lg');
    const imgc = classNames(size, 'rounded-lg shrink-0 grow-0', cover);

    return (
        <article className={ classNames(artc, overflow) }>
            <Image className={ imgc } src={ image } alt="Snapshot" />
            <div className={ classNames('grow', inner) }>
                { children }
            </div>
        </article>
    );
};

/**
 * Strict two column teaser with round thumbnail images (left).
 * It also stay always in two columns
 */
Teaser.Rounded = function RoundedTeaser({ image, title, className, size, inner='px-4', overflow='overflow-auto', cover='object-cover object-center', children }) {
    const artc = classNames(className, 'flex flex-row items-center');
    const imgc = classNames(size, 'rounded-full object-cover shrink-0 grow-0', cover);

    return (
        <article className={ classNames(artc, overflow) }>
            <Image className={ imgc } src={ image } alt="Snapshot" />
            <div className={ inner }>
                { children }
            </div>
        </article>
    );
};

export default Teaser;
