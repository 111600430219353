import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSquarePlus } from '@fortawesome/free-solid-svg-icons'; // pro: faPenCircle, faCirclePlus

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';
import Teaser from '../components/Teaser';

import { routes } from '../navigation';
import { Styles, classNames } from '../styling';

import api from '../api';
import models from '../models';


const Albums = function() {
    const [albums, setAlbums] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    // h-60 15rem  240px
    // h-80 20rem; 320px
    const teaserHeight = 'h-80 md:h-60 md:min-h-60'; // it is indeed higher in mobile wifeframes!
    const imageHeight = 'h-auto max-h-80 md:max-h-60'; // it is indeed higher in mobile wifeframes!
    const imageWidth = 'w-full md:w-80';

    useEffect(() => {
        api.albums()
            .then(res => {
                setLoading(false);
                setAlbums(res);
            })
            .catch(error => {
                setLoading(false);
                setError(error);
            });
    }, []);


    const items = models.items(albums);

    return (
        <Page.Simple>
            <PageTitle route={ routes.albums } />
            <Preloader loading={ loading } message="fetching contents" />
            <Alert.Error message={ error } />
            <IfLoaded loading={ loading }>
                <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 auto-rows-fr">
                {
                    // Ensure the items array exists before attempting to map over it
                    items.map((entry, index) => (
                        <li key={ index } className="rounded-lg">
                            <Teaser.Default
                                className={ classNames(teaserHeight, 'bg-reminu--grey-6 hover:bg-reminu--grey-3 group') }
                                image={ models.imagePath(entry, '/assets/default-album-image_50.png') }
                                size={ classNames(imageWidth, imageHeight) }
                                inner="flex flex-col gap-4 p-6"
                            >
                                <Link className="block w-full h-full flex flex-col gap-4" to={ routes.albumPages.path(0) }>
                                    <div className="grow">
                                        <h2 className={ Styles.h2 }>{ entry.name }</h2>
                                    </div>
                                    <div className="my-0 md:my-4 invisible group-hover:visible">
                                        <FontAwesomeIcon icon={ faPen } aria-hidden="true" className="pr-2" /> Edit
                                    </div>
                                </Link>
                            </Teaser.Default>
                        </li>
                    ))

                }
                </ul>

                <aside className="mt-6 md:mt-20 text-center">
                    <Link
                        to={ routes.albumEdit.path('new') }
                        className="inline-block width-auto px-8 py-4 border rounded-lg border-reminu--darker text-reminu--darker opacity-20 hover:opacity-80"
                    >
                        <FontAwesomeIcon icon={ faSquarePlus } aria-hidden="true" className="pr-2" /> Add new album
                    </Link>
                </aside>

            </IfLoaded>
        </Page.Simple>
    );
};


export default Albums;
