import { useRef, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import { routes } from '../navigation';

import { AuthContext } from '../context/AuthContext';
import { classNames, Styles } from '../styling';


const LoginForm = function({ user, login }) {

    const emailRef = useRef();
    const passwordRef = useRef();
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();

        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        return login(email, password)
            .then(() => navigate('/home'));
    };

    return (
        <form className="w-full max-w-sm" onSubmit={ onSubmit }>

            <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                    <label htmlFor="email" className="block md:text-right mb-1 md:mb-0 pr-4">
                        Email
                    </label>
                </div>

                <div className="md:w-2/3">
                    <input
                        name="email"
                        type="text"
                        ref={ emailRef }
                        className={ classNames('w-full', Styles.textInput) }
                    />
                </div>
            </div>

            <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                    <label htmlFor="password" className="block md:text-right mb-1 md:mb-0 pr-4">
                        Password
                    </label>
                </div>
                <div className="md:w-2/3">
                    <input
                        name="password"
                        type="password"
                        ref={ passwordRef }
                        placeholder=""
                        className={ classNames('w-full', Styles.textInput) }
                    />
                </div>
            </div>

            <div className="md:flex md:items-center">
                <div className="md:w-1/3"></div>
                <div className="md:w-2/3">
                    <button
                        type="submit"
                        className={ Styles.btnPrimary }
                    >Login</button>
                </div>
            </div>
        </form>
    );
};

const Login = function() {
    const context = useContext(AuthContext);
    const user = context.getUser() ;

    if (user) {
        return ( <Navigate to="/profile" replace /> );
    }

    return (
        <Page.Simple>
            <PageTitle route={ routes.login } />

            <div className="flex flex-col gap-x-4 gap-y-4">
                <div className="mt-8 flex items-center justify-center">
                    <LoginForm  user={ user } login={ context.login } />
                </div>
            </div>
        </Page.Simple>
    );
};


export default Login;
