import { useContext, useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useMatch } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import { AuthContext } from '../context/AuthContext';
import Search from './Search';

import { routes } from '../navigation';
import { classNames, Styles } from '../styling';


const primaryNavigation = [routes.home, routes.albums, routes.guidedPlans, routes.notifications];
const secondaryNavigation = [routes.search, routes.settings, routes.logout ];


const ProfileImage = function({ user, className, children }) {
    if (!user) {
        return (null);
    }

    const dimensions = 'w-11 h-11'; // 44px x 44px

    return (
        <figure className={ classNames(className, 'rounded-full', dimensions) }>
            <span className="sr-only">Open user menu</span>
            <img
                alt={ user.fullName }
                src={ user.thumbnail }
                className={ classNames(dimensions, 'rounded-full') }
            />
        </figure>
    );
}

const ProfileImageLink = function({ user, className }) {
    if (!user) {
        return (null);
    }

    return (
        <Link className={ classNames(className, 'flex items-center capitalize') } to={ routes.profile.path() }>
            <ProfileImage className="mr-2" user={ user } /> <span className="text-ellipsis">{ user.fullName }</span>
        </Link>
    );
}

const NewPage = function({ user, className }) {
    if (!user) {
        return (null);
    }

    return (
        <Link to={ routes.albumPageEdit.path(0, 'new') } className={ classNames(className, Styles.light, 'rounded-full py-2 px-4 whitespace-nowrap') }>
            <FontAwesomeIcon icon={ faPlus } aria-hidden="true" className="pr-2" />New page
        </Link>
    );
};

const Profile = function({ user }) {
    if (!user) {
        return (null);
    }

    const linkClass= 'block w-full text-left px-4 py-2 text-sm text-reminu--dark hover:bg-reminu--grey-3';
    const { profile, settings, logout } = routes;

    return (
        <>
            <span className="truncate max-w-32 pl-2 text-sm font-medium whitespace-nowrap">{ user.fullName }</span>
            <Menu as="div" className="relative ml-3">
                <div>
                    <MenuButton className="relative flex">
                        <ProfileImage user={ user } className="bg-gray-800 text-sm" />
                    </MenuButton>
                </div>

                <MenuItems
                    transition
                    className={
                        classNames(
                            Styles.light,
                            'absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white'
                        )
                    }
                >
                    <MenuItem>
                        <Link to={ profile.path() } className={ linkClass }>
                            Your Profile
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to={ settings.path() } className={ linkClass }>
                            Settings
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to={ logout.path() } className={ linkClass }>
                            Sign out
                        </Link>
                    </MenuItem>
                </MenuItems>
            </Menu>

        </>
    );
};

const NotAuthMenu = function({ user, className }) {
    const match = useMatch('/login');

    if (match) {
        return (null);
    }

    if (user) {
        return (null);
    }

    return (
        <div className={ className }>
            <NavLink
                to={ routes.login.path() }
                aria-current={ ({ active }) => active ? 'page' : null }
                className={ Styles.btnPrimaryInversed }
                title={ routes.login.name }
            >
                { routes.login.name }
            </NavLink>
        </div>
    );
};

const SmallScreenMenu = function({ user, open, toggleOpen, className }) {
    if (!user) {
        return (null);
    }

    const { search, profile } = routes;
    const sharedClass = 'p-2';

    const dimensions = 'w-10 h-10'; // 40px x 40px

    return (
        <>
            <NavLink
                to={ profile.path() }
                aria-current={ ({ active }) => active ? 'page' : null }
                className={ sharedClass }
                title={ profile.name }
            >
                <ProfileImage user={ user } dimensions={ dimensions } />
            </NavLink>

            <NavLink
                to={ search.path() }
                aria-current={ ({ active }) => active ? 'page' : null }
                className={ sharedClass }
                title={ search.name }
            >
                <FontAwesomeIcon icon={ search.icon } aria-hidden="true" />
            </NavLink>

            { /* Mobile menu button (hamburger) */ }
            <button
                className={ classNames(sharedClass, 'group relative inline-flex items-center justify-center text-reminu--light focus:outline-none') }
                onClick={ toggleOpen }
                >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                { (open) ? <FontAwesomeIcon icon={ faXmark } aria-hidden="true" className="block h-6 w-6" /> : <FontAwesomeIcon icon={ faBars } aria-hidden="true" className="block h-6 w-6" /> }
            </button>

        </>
    );
};

const SmallScreenDropdown = function({ user, className, open}) {
    if (!open || !user) {
        return (null);
    }

    const gap = 'h-40'; // 160px empty space, see layouts

    return (
        <nav className={ classNames(className, 'border-b border-reminu--grey-0') }>
            <div className="space-y-4 px-2 py-4 mb-4">
                <NewPage user={ user } />
                <PrimaryNavigation className="ml-4 text-xl font-medium" user={ user } vertical />
                <div className={ classNames(gap, 'h-40') }>&nbsp;</div>
                <ProfileImageLink className="ml-2 text-xl font-medium" user={ user } />
                <Search className="border-reminu--grey-1 text-xl font-medium" />
            </div>
        </nav>
    );
};

const SecondaryNavigation = function({ user, className }) {
    if (!user) {
        return (null);
    }

    const cls = classNames(className, 'pl-3 hover:text-gray-400');

    return (
        <>
            {
                secondaryNavigation.map((item) => (
                    <NavLink
                        key={ item.name }
                        to={ item.path() }
                        aria-current={ ({ active }) => active ? 'page' : null }
                        className={ cls }
                        title={ item.name }
                    >
                        <FontAwesomeIcon icon={ item.icon } aria-hidden="true" />
                    </NavLink>
                ))
            }
        </>
    );
};

/**
 * Horizontal menu of main pages: Home, Albums, Guide plans Notifications
 * Large screens only
 */
const PrimaryNavigation = function({ user, className, vertical=false }) {
    if (!user) {
        return (null);
    }

    const wrapper = (vertical) ? 'flex flex-col' : 'flex flex-row space-x-4';
    const size = (vertical) ? 'py-2' : 'h-16 px-3 py-6';
    const border = (vertical) ? '' :  'border-t-4 border-transparent';
    const active = (vertical) ? 'text-reminu--light' : 'text-reminu--light border-t-reminu--light';
    const link = classNames(size, border, 'hover:text-reminu--grey-3 whitespace-nowrap');

    return (
        <div className={ classNames(wrapper, className) }>
            {
                primaryNavigation.map((item) => (
                    <NavLink
                        key={ item.name }
                        to={ item.path() }
                        aria-current={ ({ isActive }) => (isActive) ? 'page' : null }
                        className={ ({ isActive }) => (isActive) ? `active ${link} ${active}` : link }
                    >
                        <span>{ item.icon && <FontAwesomeIcon icon={ item.icon } aria-hidden="true" className="pr-2" /> }{ item.name }</span>
                    </NavLink>
                ))
            }
        </div>
    );
};

const Appbar = function() {
    const [open, setOpen] = useState(false);

    const location = useLocation();
    const context = useContext(AuthContext);

    const user = context.getUser();
    const toggleOpen = () => setOpen(!open);

    // listen to route changes and close mobile dropdown
    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <Disclosure as="nav">
            <div className="mx-auto">
                <div className="relative flex h-16 items-center justify-between">

                    <div className="flex flex-initial items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center">
                            <Link to={ '/home' }>
                                <img
                                    alt="reminu"
                                    src="/assets/reminu-logo-white.svg"
                                    className="h-8 w-auto"
                                />
                            </Link>
                        </div>
                    </div>

                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="hidden lg:block sm:ml-6">
                            <PrimaryNavigation user={ user } className="text-sm font-medium" />
                        </div>
                    </div>

                    <div className="absolute inset-y-0 right-0 items-center pr-2 hidden lg:flex sm:ml-6 sm:pr-0">
                        <NewPage user={ user } className="hidden lgr:block" /> { /* custom breakpoint (tailwind.config.js */ }
                        <Profile user={ user } />
                        <SecondaryNavigation user={ user } />
                    </div>

                    <NotAuthMenu user={ user } className="absolute inset-y-0 right-0 flex items-center pr-0" />

                    <div className="absolute inset-y-0 right-0 lg:hidden flex items-center">
                        <SmallScreenMenu user={ user } open={ open } toggleOpen={ toggleOpen } />
                    </div>
                </div>
            </div>

            <SmallScreenDropdown user={ user } open={ open } />

        </Disclosure>
    );
};


export default Appbar;
