import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faShare } from '@fortawesome/free-solid-svg-icons'; // pro: faCirclePlus

import Alert from '../components/Alert';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import Preloader from '../components/Preloader';
import IfLoaded from '../components/IfLoaded';

import Modal from '../components/Modal';
import Image from '../components/Image';
import Media from '../components/Media';

import { routes } from '../navigation';
import { classNames } from '../styling';
import api from '../api';

import { mockPageLayoutPage, mockPageLayoutPageParagraphs } from '../mock-data';


const createPageRoute = function (page) {
    return {
        id: (page) ? page.id : -1,
        name: (page) ? page.title :  '',
        path: (page && page.album) ? routes.albumPage.path(page.album.id, page.id) : null,
        icon: faSquarePlus,
    };
};

const AlbumPageParagraphView = function({ paragraph, className }) {
    if (!paragraph) {
        return (null);
    }

    const grid = 'grid grid-cols-3 gap-2';
    const row = 'col-span-3';
    const col = 'col-span-3 md:col-auto';
    const media = paragraph.media || []; // model not entirely clear yet

    return (
        <article className={ classNames(grid, className) }>
            { paragraph.headline && <h3 className={ row }>{ paragraph.headline }</h3> }
            { paragraph.content && <div className={ row }>{ paragraph.content }</div> }
            {
                media.map((item, index) => (
                    <div key={ index } className={ col }>
                        <Media media={ item } />
                    </div>
                ))
            }
        </article>
    );
};

const AlbumPageView = function({ page, paragraphs, className, children }) {
    if (!page) {
        return (null);
    }

    paragraphs = paragraphs || [];

    const mb = 'mb-4';

    return (
        <section className={ className }>
            {
                page.image && <Image
                    className={ classNames(mb, 'rounded w-full object-cover max-h-72') }
                    src={ page.image } alt={ page.title }
                    title={ page.title }
                />
            } {
                page.subtitle && <div className={ mb }>{ page.subtitle }</div>
            } {
                paragraphs.map((item, index) => <AlbumPageParagraphView key={ index } page={ page } paragraph={ item } />)
            }
        </section>
    );
};


const shareTitle = (myself) => (myself) ? 'Share with others' : 'What others have shared with me';

const ShareButton = function({ className, myself, ...rest }) {
    const icon = (myself) ? <FontAwesomeIcon icon={ faShare } /> : <FontAwesomeIcon icon={ faShare } className="fa-rotate-180" />;

    return (
        <button
            className={ className }
            { ...rest }
        >
            { icon } { shareTitle(myself) }
        </button>
    );
};

ShareButton.Myself = (props) => <ShareButton myself { ...props } />
ShareButton.Others = (props) => <ShareButton { ...props } />


/**
 * Album page Preview as a dedicated route (smaal sceen layout  only)
 */
const AlbumPageLayout = function() {
    const [page, setPage] = useState(null);
    const [paragraphs, setParagraphs] = useState([]);
    const [count, setCount] = useState(0); // using this only for triggering re-renders

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            api.mock(mockPageLayoutPage),
            api.mock(mockPageLayoutPageParagraphs)
        ])
        .then((values) => {
            setPage(values[0]);
            setParagraphs(values[1]);

            setCount(values[1].length);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            setError(error);
        });
    }, []);

    return (
        <Page.Simple className="h-full">
            <PageTitle route={ createPageRoute(page) } />

            <Preloader loading={ loading } message="fetching contents" />
            <Alert.Error message={ error } />
            <IfLoaded loading={ loading }>
                <AlbumPageView page={ page } paragraphs={ paragraphs } className="mb-4" />
                <div className={ `item-${count}` }>
                    <Modal
                        BtnComponent={ ShareButton.Myself }
                        btnClassName= "block mb-2 text-reminu--dark"
                        title={ shareTitle(true) }
                        actionCallback={ () => Promise.resolve(true) }
                    >
                        <p>TODO</p>
                    </Modal>
                    <Modal
                        BtnComponent={ ShareButton.Others }
                        btnClassName= "block mb-2 text-reminu--grey-3 hover-reminu--dark"
                        title={ shareTitle(false) }
                        actionCallback={ () => Promise.resolve(true) }
                    >
                        <p>TODO</p>
                    </Modal>
                </div>
            </IfLoaded>
        </Page.Simple>
    );
};


export default AlbumPageLayout;
