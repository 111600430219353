import Image from './Image';


const defaults = {
    image: (props) => Object.assign({
        alt: 'snapshot',
    }, props),

    video: (props) => Object.assign({
        controls: true,
        autoplay: false,
        preload: 'auto',
        loop: false,
        muted: false,
    }, props),

    audio: (props) => Object.assign({
        controls: true,
        autoplay: false,
        preload: 'auto',
        loop: false,
        muted: false,
    }, props),
}

const MediaElement = function({ className, mime, src, title, ...rest }) {
    mime  = mime || '';

    let attr = {};

    if (mime.startsWith('image')) {
        attr = defaults.image(rest);
        return (
            <Image src={ src } alt={ title } { ...attr } />
        );
    }

    if (mime.startsWith('video')) {
        attr = defaults.video(rest);
        return (
            <video { ...attr }>
                <source src={ src } type={ mime } />
            </video>
        );
    }

    if (mime.startsWith('audio')) {
        attr = defaults.audio(rest);
        return (
            <audio { ...attr }>
                <source src={ src } type={ mime } />
            </audio>
        );
    }

    return (
        <div className="px-4 py-2 border border-status-error-dark text-status-error-dark">
            Unknown media of type "<strong>{ mime }</strong>"
        </div>
    );

}

const Media = function({ className, media, captionClassName='hidden', ...rest }) {

    if (!media) {
        return (null);
    }
    const  { mime, s3_url, title, caption } = media;

    const capt = (caption) ? caption : title;
    const alt = (title) ? title: caption;

    return (
        <figure className={ className }>
            <MediaElement mime={ mime } src={ s3_url } title={ alt } { ...rest } />
            { caption && <figcaption className={ captionClassName }>{ capt }</figcaption> }
        </figure>
    );
}


export default Media;
