const gets = (obj, key, _def=null) => (obj && typeof obj[key] !== 'undefined' && obj[key]) ? obj[key] : _def;

const timeout = function(callback, millisec=1000) {
    let tid;
    return new Promise((resolve) => {
        tid = setTimeout(() => {
            clearTimeout(tid);
            resolve(callback());
        }, millisec);
    });
};

const uuidv4 = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line no-bitwise, eqeqeq, no-mixed-operators
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export { gets, timeout, uuidv4 };
