const If = {};

If.True = function IfTrue({ test, children }) {
    if (!test) {
        return (null);
    }
    return (<>{ children }</>);
};

If.False = function IfFalse({ test, children }) {
    if (test) {
        return (null);
    }
    return (<>{ children }</>);
};

If.Loaded = function IfLoaded({ loading, children }) {
    if (loading) {
        return (null);
    }
    return (<>{ children }</>);
};

export default If;
