import { useContext } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AppContext } from '../context/AppContext';

import { classNames, Styles, Brand } from '../styling';
import { routes } from '../navigation';

const navigation = [routes.search, routes.settings, routes.logout];
const yyyy = new Date().getFullYear();


const Footer = function({ className, dark }) {

    const context = useContext(AppContext);
    const colours = (context.dark) ? ['md:text-reminu--light', Styles.darkGrey] : ['md:text-reminu--dark', Styles.lightGrey];

    return(
        <footer className={ classNames('text-sm', className, colours.join(' ')) }>
            <div className="flex justify-between flex-col-reverse md:flex-row md:items-center gap-2">
                <div>
                    <span className="pr-2">Copyright { yyyy }</span> Innovation by <Link to={ Brand.owner.website } className="hover:underline" target="_blank">{ Brand.owner.name }</Link>
                </div>
                <ul className="flex flex-wrap md:items-center text-sm font-medium sm:mt-0">
                {
                    navigation.map((item, index) => (
                        <li key={ index }>
                            <Link
                                to={ item.path() }
                                aria-current={ ({ active }) => active ? 'page' : null }
                                className={ classNames((index < navigation.length - 1) ? 'mr-3': '', 'hover:underline') }
                            >
                                <FontAwesomeIcon icon={ item.icon } aria-hidden="true" className="pr-1" /> { item.name }
                            </Link>
                        </li>
                    ))
                }
                </ul>
            </div>
        </footer>
    );
};


export default Footer;
